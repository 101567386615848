import { FC, useMemo } from 'react';

import DataList, { DataListItem } from 'components/UI/DataList';
import { useTranslations } from 'hooks/useTranslations';
import { Product, Warranty } from 'models/product';
import { dateToStringInFormat, getDateFromWarrantyDate } from 'utils/dateUtils';

import { Container, InformationSection, WarrantyDisclaimer } from './styled';

type Props = {
  product: Product;
  warranty: Warranty | null;
};

const ProductInformation: FC<Props> = ({ product, warranty }) => {
  const t = useTranslations();

  const productInformationData: DataListItem[] = useMemo(() => {
    return [
      {
        name: t('productdetails.data_pnc'),
        value: product?.pnc
      },
      {
        name: t('productdetails.data_serial'),
        value: product?.serialNumber
      },
      {
        name: t('productdetails.data_hid'),
        value: product?.husqvarnaId
      },
      {
        name: t('productdetails.data_sold_date'),
        value:
          warranty?.soldDate &&
          dateToStringInFormat(
            getDateFromWarrantyDate(warranty.soldDate),
            'yyyy-MM-dd'
          )
      },
      {
        name: t('productdetails.data_warranty_expiration_date'),
        value:
          warranty?.expireDate &&
          dateToStringInFormat(
            getDateFromWarrantyDate(warranty.expireDate),
            'yyyy-MM-dd'
          )
      }
    ];
  }, [t, product, warranty]);

  return (
    <Container>
      <InformationSection>
        <DataList data={productInformationData} />
      </InformationSection>
      <WarrantyDisclaimer>
        {t('productdetails.warranty_disclaimer')}
      </WarrantyDisclaimer>
    </Container>
  );
};

export default ProductInformation;
